import React, { useState, useEffect } from 'react';
import { WP_SERVER_URL } from '../utils/config';

// to get specific data: validate jwt and server side use that username to fetch data. Make sure key is needed to access enpoint.
function ProfilePage(props) {
  const [pageText, setPageText] = useState('You are not currently logged in. Please login to see your user profile')
  const [email, setEmail] = useState('')
  const [ID, setID] = useState('')
  const [regDate, setRegDate] = useState('')

  useEffect(()=>{
    let userJWT = localStorage.getItem('jwt') || ''
    console.log(userJWT)
      if (userJWT.length > 0) {
        let url = WP_SERVER_URL + `/?rest_route=/simple-jwt-login/v1/auth/validate&JWT=${userJWT}`
        fetch(url)
          .then(response => response.json()) 
          .then(data => {
            if (data['success']===true){
              console.log('user was persisted!')
              localStorage.setItem('id', data['data']['user']['ID'])
              localStorage.setItem('role', data['data']['roles'])
              setEmail(`Email: ${data['data']['user']['user_email']}`)
              setID(`ID: ${data['data']['user']['ID']}`)
              setRegDate(`Date of registration: ${data['data']['user']['user_registered']}`)
              setPageText(`${data['data']['user']['user_login']}'s User Profile`)
              props.setIsLoggedIn(true)
              props.setUsername(data['data']['user']['user_login'])
              props.setServerMessage('')
            }
            else {
              console.log(data)
              console.log('Persist failed')
              props.setServerMessage(data['data']['message'])
            }

          })
      }
    }, [])
    setTimeout(function() {
      window.location.replace('/profile');
    }, 2000);
  return (
    <div className="content-app">
      <header className="app-login">
        <h1>Успешно влязохте във вашия профил</h1>
        <p>Моля изчакайте</p>
        {/* <p>{props.serverMessage}</p>
        <p>{pageText}</p>
        <p>{email}</p>
        <p>{ID}</p>
        <p>{regDate}</p> */}
      </header>
    </div>
  );
}

export default ProfilePage;