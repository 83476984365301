import React from 'react';

function Statistics() {
    return (
        <div className="content-app">
        <h1>this is the statistics page</h1>
        </div>
    );
}

export default Statistics;