import React, { Component } from 'react'
import PropTypes from 'prop-types';


export class VimeoQuality extends Component {

    static propTypes = {
        test1: PropTypes.object.isRequired
    }

    state = {
        test1: [],
        isLoaded: false
    };

    

    render() {
        const { test1, width, isLoaded} = this.props.test1;
        // console.log(this.props.test1)
    //     const names = Object.keys(this.props.test1)
    //     .filter((key) => key.includes("link"))
    //     .reduce((obj, key) => {
    //         return Object.assign(obj, {
    //           [key]: this.props.test1[key]
    //         });
    //   }, {});

    //   var name = 'hd';
    //   Object.keys(this.props.test1).find((obj) => {
    //     if (obj.name === name) console.log('found!');
    //     return (obj.name === name);
    // });
    
    // console.log(this.props.test1[newD]);
    
    // console.log(names);
        // const foundCompanyRoleItem = width.find(this.props.test1 => this.props.test1.quality === 1080);
        // const found = width.find(qwe => qwe > 10);
        // const names = Object.keys(this.state);
        // console.log(foundCompanyRoleItem);

        // console.log(this.state);
        // test1.width.find(
        //     (element) => element.CategoryName === "Rice",
        //   );
        return (
        <div>
                        {/* {this.props.test1.filter(videos => videos.quality == "hd").map(filteredVideos => (
                            <li>
                            {filteredVideos.width}
                            </li>
                        ))} */}
        </div>
        
            
        )
    }
}
export default VimeoQuality