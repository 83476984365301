import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export class CategoriesItems extends Component {

    static propTypes = {
        category: PropTypes.object.isRequired
    }

    render() {
        const {acf, id, isLoaded} = this.props.category;
        console.log(this.state);
        return (
            <div className="col-lg-4 col-md-8 mb-4 carousel-item-hover">
                <Link to={`/series/${id}`}><img src={acf.image} alt="" className="img-fluid img" /></Link>
            </div>
        )
    }
}
export default CategoriesItems