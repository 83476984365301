import React, { Component } from 'react'
import axios from 'axios';
import PostsItems from './PostsItems';
import { WP_SERVER_URL } from '../utils/config';

export class Posts extends Component {

    state = {
       posts: [],
       isLoaded: false
    }

    componentDidMount () {
        axios.get(WP_SERVER_URL + '/wp-json/wp/v2/posts?per_page=12')
        .then(res => this.setState({
            posts: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err))
    }

    render() {
        const {posts, isLoaded} = this.state;
        return (

            <section id="services" className="services">
                <div className="container">
                    <div className="section-header-left">
                        <h2>Най-нови класове</h2>
                    </div>
                    <div className="row gy-5">
                            {posts.slice(0, 12).map(post =>
                                <PostsItems key={post.id} post={post}/>
                            )}
                    </div>
                </div>
            </section>
        )
    }
}

export default Posts