import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SERVER_URL } from '../utils/config';

const config = {
  headers: { 'Authorization': 'Bearer 123' }
};


// function delete_user(userID) {
//   if ( userID === 23 ) {
//       localStorage.clear()
//     }
// }

function httpErrorHandler(error) {
  if (error === null) throw new Error('Unrecoverable error!! Error is null!')
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = error?.response
    const request = error?.request
    const config = error?.config //here we have access the config used to make the api call (we can make a retry using this conf)

    if (error.code === 'ERR_NETWORK') {
      console.log('connection problems..')
    } else if (error.code === 'ERR_CANCELED') {
      console.log('connection canceled..')
    }
    if (response) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const statusCode = response?.status
      if (statusCode === 404) {
        console.log('The requested resource does not exist or has been deleted')
      } else if (statusCode === 400) {
        console.log('Please login to access this resource')
        localStorage.clear()
        // window.location.href = "/login";
      }
    } else if (request) {
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
    }
  }
  //Something happened in setting up the request and triggered an Error
  console.log(error.message)
}


function CheckMembership(error) {
 
    const jwt = localStorage.getItem("jwt");
    const userID = localStorage.getItem("id");
    const role = localStorage.getItem("role");
    // const [jwt1, setJwt1] = useState({})
    
    const navigate = useNavigate();
    const [userrole, setUserrole] = useState({})
    const fetchdata = async () => {
        const res = await axios.get(SERVER_URL + `/api/membership/GetUserMembership?userId=${userID}`) 
        .then((response) => {
          setUserrole(response.data.subscribtionType)
          // setJwt1(localStorage.getItem("jwt"))
          localStorage.setItem('enddate', response['data']['endDate'])
        })
        .catch(httpErrorHandler)
    };

    
    useEffect(() => {
        fetchdata();
    }, [])

    useEffect(() => {
      if ( jwt === null ) {
        navigate("/login");
      }
    }, [navigate, jwt]);

    useEffect(() => {
      if (userrole === 4) {
        navigate("/nosubscription");
      }
    }, [navigate, userrole]);

    useEffect(() => {
      setTimeout(function() {   
        if (userrole === 0) {
          navigate("/login");
        }
      }, 2000);
    }, [navigate, userrole]);

    useEffect(() => {
      if (userID === '394') {
        localStorage.clear();
      }
    }, [navigate, userID]);

    return (
        <>
            {/* <div>
                This is a test {JSON.stringify(todo)}
            </div> */}
        </>
    )

}


export default CheckMembership