import React, { useState, useEffect } from 'react'
import LoginAPI from './LoginAPI';

function Login(props) {
    const [APIDetailsLogin, setAPIDetailsLogin] = useState({
        user: '',
        pass: '',
      })
    const [loginDetails, setLoginDetails] = useState({
        user: '',
        pass: '',
    })

    function handleChange(e) {
        const { name, value } = e.target
        setLoginDetails(prev => {
            return (
                { ...prev, [name]: value }
            )
        })
    }

    function handleSubmit() {
        setAPIDetailsLogin({ ...loginDetails }) //check i need the ...here
    }

    const handleKeypress = e => {  
        if (e.charCode === 13) {
            handleSubmit();  
        }  
    };

    return (
        <>
            <div className="content-app">
                <header className="app-login">
                    <h2>Влез във FlexyFit WebApp</h2>
                    <p>{props.serverMessage}</p>
                    <div className="login">
                        <input type="text" placeholder="Имейл" name="user" value={loginDetails.user} onChange={handleChange} onKeyPress={handleKeypress} />
                        <input type="password" placeholder="Парола" name="pass" value={loginDetails.pass} onChange={handleChange} onKeyPress={handleKeypress} />
                        <input type="submit" value="Влез" onClick={handleSubmit} />
                    </div>
                </header>
            </div>
            <LoginAPI APIDetailsLogin={APIDetailsLogin} setUsername={props.setUsername} setIsLoggedIn={props.setIsLoggedIn} setServerMessage={props.setServerMessage} />
        </>
    )
}

export default Login