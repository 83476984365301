import React from 'react';

function Settings() {
    return (
        <div className="content-app">
        <h1>this is the contacts page</h1>
        </div>
    );
}

export default Settings;