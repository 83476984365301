import React, { Component } from 'react'
import {useParams} from 'react-router-dom';
import VideoPlayer from 'react-video-js-player';
// import { Link } from "react-router-dom";
import axios from 'axios';
import 'video.js/dist/video-js.css';
import SingleSeries from '../components/SingleSeries';
import { WP_SERVER_URL } from '../utils/config';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const wptoken = `${process.env.REACT_APP_API_KEY_WP}`;

const config = {
        headers: { 'Authorization': `${wptoken}` }
};

const configvimeo = {
    headers: { 'Authorization': 'Bearer 5624d7833fa48edd99116442d0dd1008' }
};

export class SingleSeriesMain extends Component {

    onPlayerReady(player){
        console.log("Player is ready: ", player);
        this.player = player;
    }
  
    onVideoPlay(duration){
        console.log("Video played at: ", duration);
    }
  
    onVideoPause(duration){
        console.log("Video paused at: ", duration);
    }
  
    onVideoTimeUpdate(duration){
        console.log("Time updated: ", duration);
    }
  
    onVideoSeeking(duration){
        console.log("Video seeking: ", duration);
    }
  
    onVideoSeeked(from, to){
        console.log(`Video seeked from ${from} to ${to}`);
    }
  
    onVideoEnd(){
        console.log("Video ended");
    }

    state = {
        showIframe: false,
        singleseries_info: [],
        videos_info: [],
        isLoaded: false
    };
    

    async componentDidMount() {

        // Make first two requests
        const [firstResponse] = await Promise.all([
          axios.get(WP_SERVER_URL + `/wp-json/wp/v2/categories?include=${this.props.params.id}`, config),
        ]);

        const thirdResponse = await axios.get(`https://api.vimeo.com/me/videos/${firstResponse.data[0].vimeo_video_id}`, configvimeo);
      
        // Update state once with all 3 responses
        this.setState({
            singleseries_info: firstResponse.data[0],
            videos_info: thirdResponse.data.files,
            showIframe: true,
            isLoaded: true
        });
      
    }

    render() {
        this.state.videos_info.sort((a,b)=> (a.rendition > b.rendition ? 1 : -1))
        const video = this.state.videos_info[0];
        const { showIframe } = this.state;
        const {singleseries_info, isLoaded} = this.state;
        console.log(this.state);
        console.log(this.state);
        return (
        
            <div className="content-app">
                { showIframe && 
                    
                    <VideoPlayer
                    controls={true}
                    src={video.link}
                    height="898"
                    onReady={this.onPlayerReady.bind(this)}
                    onPlay={this.onVideoPlay.bind(this)}
                    onPause={this.onVideoPause.bind(this)}
                    onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                    onSeeking={this.onVideoSeeking.bind(this)}
                    onSeeked={this.onVideoSeeked.bind(this)}
                    onEnd={this.onVideoEnd.bind(this)}
                    />
                }
                <SingleSeries 
                    id={this.props.params.id}
                />
            </div>


        )
    }
}

export default withParams(SingleSeriesMain);