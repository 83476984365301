import React from 'react';
import { Link } from "react-router-dom";

function Contact() {
    return (
        <div className="content-app">
            <div className="container">
                <div className="text-center">
                    <img src="/assets/img/404.png" alt="404" />
                    <div class="headline-wrapper">
                        <h1 class="headline">Нещо се обърка.</h1>
                        <p class="headline-copy">
                        Това е 404 грешка за липсваща страница. Това означава, че сте натиснали несъществуващ линк и това е нереален адрес.<br />
                        Може да се върнете на главната страница като натиснете тук <Link to="/">FlexyFit начална страница</Link>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;