import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export class InstructorsItems extends Component {

    static propTypes = {
        instructor: PropTypes.object.isRequired
    }

    render() {
        const {instructor_avatar, id, name, trainer_desc, isLoaded} = this.props.instructor;
        console.log(this.state);
        return (
            <div className="team-member">
                <div className="member-img">
                    <img src={instructor_avatar.guid} className="img-fluid" alt="" />
                </div>
                <Link to={`/instructor/${id}`} onClick={this.forceUpdate}>
                <div className="member-info">
                    <h4>{name}</h4>
                    <span>{trainer_desc}</span>
                </div>
                </Link>
            </div>
        )
    }
}
export default InstructorsItems