import React, { Component } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import SingleSeriesItems from './SingleSeriesItems';
import { WP_SERVER_URL } from '../utils/config';

export class SingleSeries extends Component {

    state = {
        singleseries: [],
        singleseries_categ: [],
        instructor: [],
        isLoaded: false
    }

    
    componentDidMount () {

        axios.get(WP_SERVER_URL + `/wp-json/wp/v2/posts?categories=${this.props.id}&acf_format=standard&orderby=order&order=asc&per_page=100`)
        .then(res => this.setState({
            singleseries: res.data,
            instructor: res.data[0].instructor[0],
            instructor_avatar: res.data[0].instructor[0].instructor_avatar,
            isLoaded: true
        }))
        .catch(err => console.log(err))
        axios.get(WP_SERVER_URL + `/wp-json/wp/v2/categories?include=${this.props.id}`)
        .then(res => this.setState({
            singleseries_categ: res.data[0],
            isLoaded: true
        }))
        .catch(err => console.log(err))
        
    }

    render() {
        const {singleseries, singleseries_categ, last_watched, instructor, instructor_avatar, isLoaded} = this.state;
 
        return (

            <section id="portfolio-details" className="portfolio-details">
                <div className="container aos-init aos-animate" data-aos="fade-up">
                    <div className="row gy-4">
                        <div className="col-lg-3">
                            <Link to={`/instructor/${instructor.id}`}>
                                <div className="avatar-item-profile">
                                <img src={instructor_avatar} className="avatar-img-profile" alt="" />
                                <div><strong>{instructor.display_name}</strong></div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-1">
                        </div>

                        <div className="col-lg-8 profile-act-sh">
                            <div className="trainer-info">
                            <h3>{singleseries_categ.name}</h3>
                            {singleseries_categ.description}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="services" className="services mt-5">
                    <div className="container">
                        <div className="section-header-left">
                            <h2>Класове в тази серия</h2>
                        </div>
                        <div className="row gy-5">
                            {singleseries.map(singleserie =>
                                <SingleSeriesItems key={singleserie.id} singleserie={singleserie} />
                            )}
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}

export default SingleSeries;