import React from 'react';

function populateStorage() {
    localStorage.clear()
    window.location.href = "/login";
  }

function NoSubscription() {
    return (
        <div className="content-app mt-5 mb-5">
            <div className="container col-lg-4 mt-5 mb-5">
                <div className="text-center">
                    <div class="headline-wrapper">
                        <h1 class="headline">Вашият абонамент е изтекъл.</h1>
                        <p class="headline-copy">
                        Ако вече сте платил(а) за съответния месец, моля влезте в мобилното приложение, за да се актуализират данните за Вашият абонамент.
                        В случай на грешка, моля пишете ни на info@flexyfit.bg.
                        </p>
                    </div>
                    <div className="logout"><button className="btn logout-btn w-25" onClick={populateStorage}>Излез</button></div>
                </div>
            </div>
        </div>
    );
}

export default NoSubscription;