import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export class SearchResultsVideosItems extends Component {

  static propTypes = {
      searchvideo: PropTypes.object.isRequired
  }

  render() {
    const {fimg_url, slug, title, instructor, video_id, physical_difficulty, technical_difficulty, video_length, isLoaded} = this.props.searchvideo;
    console.log(this.state);
    return (
      <div className={`filter-item instructor-${instructor[0].id} physical_difficulty-${physical_difficulty} technical_difficulty-${technical_difficulty} col-lg-3 col-sm-6 featured-services item ${ slug }`}>
        <Link to={`/video/${video_id}`}>
        
          <div className="portfolio-wrapper service-item">
            
            <div className="img">
            <div className="video-length">{video_length}</div>
              {/* <div class="icon"><i class="bi bi-activity icon"></i></div> */}
              <img src={fimg_url} alt="" className="img-fluid img videos-img" />
              <div className="series-desc">
                  <div className="series-name">{title.rendered}</div>
                  <div className="series-trainer">{instructor[0].display_name}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }
}
export default SearchResultsVideosItems