import React, { Component } from 'react'
import {useParams} from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import InstructorsSeries from '../components/InstructorsSeries';
import Instructors from '../components/InstructorsHome';
import { WP_SERVER_URL } from '../utils/config';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const wptoken = `${process.env.REACT_APP_API_KEY_WP}`;

const config = {
        headers: { 'Authorization': `${wptoken}` }
};

const config_vimeo = {
    headers: { 'Authorization': 'Bearer 5624d7833fa48edd99116442d0dd1008' }
};

export class Instructor extends Component {

    state = {
        instructor: [],
        instructor_avatar: [],
        videos_info: [],
        isLoaded: false
    }

    async componentDidMount () {
        const [firstResponse] = await Promise.all([
        axios.get(WP_SERVER_URL + `/wp-json/wp/v2/users/${this.props.params.id}`, config),
    ]);

        // const {instructor} = this.state;
        const thirdResponse = await axios.get(`https://api.vimeo.com/me/videos/${firstResponse.data.instructor_video_id}`, config_vimeo);
        this.setState({
            instructor: firstResponse.data,
            instructor_avatar: firstResponse.data.instructor_avatar,
			videos_info: thirdResponse.data.files,
            showIframe: true
        });
    }

    render() {
        this.state.videos_info.sort((a,b)=> (a.rendition > b.rendition ? 1 : -1))
        const video = this.state.videos_info[0];
        const {instructor, instructor_avatar, showIframe, isLoaded} = this.state;
        
        return (
            <div className="content-app">
                { showIframe && 
                    <iframe src={`${video.link}`} className="iframe-bg" width="100%" height="800px" autoPlay={false} frameBorder="0" allowFullScreen></iframe>
                }
                <section id="portfolio-details" className="portfolio-details">
                    <div className="container aos-init aos-animate" data-aos="fade-up">

                        <div className="row gy-4">

                        <div className="col-lg-3">
                            <div className="avatar-item-profile">
                            <img src={instructor_avatar.guid} className="avatar-img-profile" alt="" />
                            <div><strong>{instructor.name}</strong></div>
                            </div>
                        </div>

                        <div className="col-lg-1">

                        </div>

                        <div className="col-lg-8 profile-act-sh">
                            <div className="trainer-info">
                            <h5>{instructor.description}</h5>
                            {/* <div><Link to="#">Виж още</Link></div> */}
                            </div>
                        </div>
                        <InstructorsSeries 
                            id={this.props.params.id}
                        />

                        <Instructors />
                        </div>

                    </div>
                </section>
            </div>

        )
    }
}

export default withParams(Instructor);