import {useState, useEffect} from 'react';
import axios from 'axios';
import { SERVER_URL } from '../utils/config';


function dateFormat(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const today = new Date(),
  time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

  return `${day}/${month}/${year} ${time}`;
}

export const WatchTimePost = ({video, video_categories, class_id}) => {

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const userId = localStorage.getItem("id");
  const today = new Date(),
  time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  const handleClick = async () => {

    setIsLoading(true);
    try {
      const currentDate = dateFormat(new Date());
      const {data} = await axios.post(
        SERVER_URL + '/api/video/AddUserVideoData',
        {userId: (userId), videoId: (video), "isInstructor": 0, duration:"30", dateRecord: (currentDate), deviceOS: "WebApp", id: (class_id), videoType: "1", videoCategories: (video_categories)},
      );
      // console.log(time);
      // console.log(JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const MINUTE_MS = 30000;

  useEffect(() => {
    const interval = setInterval(() => {
      handleClick()
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [])

  return (
    <div>
      
    </div>
  );
};

export default WatchTimePost