import React, { Component } from 'react'
import {useParams} from 'react-router-dom';
// import { Link } from "react-router-dom";
// import axios from 'axios';
import IsotopeFiltersTest from '../components/IsotopeFiltersTest'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
export class IsotopeFiltersTestMain extends Component {

    state = {
        series: [],
        isLoaded: false
    }

    render() {
        //const {name, id, isLoaded} = this.state;
        console.log(this.state);
        return (
                    <div className="content-app">
                        <IsotopeFiltersTest id={this.props.params.id} />
                    </div>
                    // <div className="col-xl-4 col-md-6" data-aos="zoom-out">
                    //     <div className="service-item">
                    //         <Link to={`/series/${id}`}>
                    //             <div className="img">
                    //                 {/* <img src={acf.image} alt={name.rendered} className="img-fluid" /> */}
                    //             </div>
                    //             <div className="details position-relative">
                    //                 <div className="icon">
                    //                 <i className="bi bi-play-circle"></i>
                    //                 </div>
                    //                 {/* <h3>{name.rendered}</h3> */}
                    //                 <p>Времетраене 12:48</p>
                    //             </div>
                    //         </Link>
                    //     </div>
                    // </div>


        )
    }
}

export default withParams(IsotopeFiltersTestMain);