import React, { Component } from 'react'
import {useParams, Link} from 'react-router-dom';
import axios from 'axios';
import FakeSuspense from '../components/FakeSuspense'
import { SERVER_URL } from '../utils/config';
import { WP_SERVER_URL } from '../utils/config';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const refreshPage = ()=>{
    setTimeout(() => window.location.reload(), 1000);
}

export class VideoDetails extends Component {

    state = {
        video_details: [],
        video_categories: [],
        class_id: [],
        video_instructor: [],
        title: [],
        favorites: [],
        userID: localStorage.getItem('id') || 0,
        isLoaded: false
    }

    async componentDidMount () {
        const user_id = this.state.userID;
        const [firstResponse, secondResponse] = await Promise.all([
        axios.get(WP_SERVER_URL + `/wp-json/wp/v2/posts?video_id=${this.props.params.id}`),
        
        axios.post(SERVER_URL + '/api/favorites/GetUserFvorites',
        {userId: (user_id)}
        )
            
    ]);

        this.setState({
            video_details: firstResponse.data[0],
            video_categories: firstResponse.data[0].categories,
            class_id: firstResponse.data[0].id,
            video_instructor: firstResponse.data[0].instructor[0],
            favorites: secondResponse.data.favorites.toString(), //JSON.stringify(secondResponse.data.favorites).replace(/['"]+/g, ''),
            isLoaded: true
        });
        // console.log(video_id)
    }

    render() {
        const AddFavorites = () => {
            axios.post(SERVER_URL + '/api/favorites/AddRemoveUserFavorites', 
            {"userId": (this.state.userID), "videoId": (this.state.class_id), "operation": 0}
            ).then(function (response) {
               console.log(response.data);
               console.log(this.state.video_details.id)
            })
       };
       const RemoveFavorites = () => {
            axios.post(SERVER_URL + '/api/favorites/AddRemoveUserFavorites', 
            {"userId": (this.state.userID), "videoId": (this.state.class_id), "operation": 1}
            ).then(function (response) {
                console.log(response.data);
            })
        };

       
        const {video_details, video_instructor, favorites, video_categories, isLoaded} = this.state;
        const arr_fav = favorites;
        const arr_match = this.state.class_id;
        // console.log(arr_fav.includes(arr_match)); // true
        // console.log(this.state.video_id)
        return (
            <section>
                <div className="container">
                    <div className="section-header-left">                           
                        {video_details.title && (
                            <h2>{video_details.title.rendered}</h2>
                        )}
                    <h4><Link to={`/instructor/${video_instructor.ID}`}>{video_instructor.display_name}</Link></h4>
                    <h5>Категория: <Link to={`/series/${video_categories[0]}`}>{video_details.parent_category}</Link> | Серия: <Link to={`/singleseries/${video_categories[1]}`}>{video_details.child_category}</Link></h5>
                    </div>
                    <div className="d-inline-flex">  
                        <div className="col-lg-5">
                            <div className="video-description">
                                {video_details.content && (
                                    <div dangerouslySetInnerHTML={{ __html: video_details.content.rendered }} />
                                )}
                            </div>
                        </div>
                        <div className="col-lg-2">
                        </div>
                        <div className="col-lg-5">
                            <div className="video-meta">
                                <div className="video-meta-num d-inline-flex">
                                    <div className="profile-meta-single d-inline-flex"><img src="assets/img/height.png" alt="" />
                                        <div className="video-meta-stat">
                                            <div className="video-meta-title">Трудност</div>
                                            {video_details.technical_difficulty && (
                                                <div dangerouslySetInnerHTML={{ __html: video_details.technical_difficulty }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-meta-single d-inline-flex"><img src="assets/img/weight.png" alt="" />
                                    <div className="video-meta-stat">
                                        <div className="video-meta-title">Интензитет</div>
                                        {video_details.physical_difficulty && (
                                            <div dangerouslySetInnerHTML={{ __html: video_details.physical_difficulty }} />
                                        )}
                                    </div>
                                </div>
                                <div className="profile-meta-single d-inline-flex"><img src="assets/img/age.png" alt="" />
                                    <div className="video-meta-stat">
                                        <div className="video-meta-title">Времетраене</div>
                                        {video_details.video_length && (
                                            <div dangerouslySetInnerHTML={{ __html: video_details.video_length }} />
                                        )}
                                    </div>
                                </div>
                                {/* <FakeSuspense delay={3000} > */}
                                {arr_fav.includes(arr_match) ? 
                                    <a className="btn-favourites fav-remove" onClick={()=> {RemoveFavorites(); refreshPage()}}>
                                        Премахни от любими класове
                                    </a>
                                :
                                    <a className="btn-favourites" onClick={()=> {AddFavorites(); refreshPage()}}>
                                        Добави в любими класове
                                    </a>
                                }
                                {/* </FakeSuspense> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}

export default withParams(VideoDetails);