import React, { Component, useState, useEffect } from 'react'
import {useParams} from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/bg';
import axios from 'axios';
import FakeSuspense from '../components/FakeSuspense';
import { WP_SERVER_URL } from '../utils/config';


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function populateStorage() {
  localStorage.clear()
  window.location.href = "/login";
}

function dateFormat(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}${month}${day}`;
}
console.log(dateFormat(new Date()));

const wptoken = `${process.env.REACT_APP_API_KEY_WP}`;

const config = {
        headers: { 'Authorization': `${wptoken}` }
};

// const jwttest = localStorage.getItem('jwt') || 0;



const local_user = {
  headers: { 'Authorization': localStorage.getItem('jwt') }
};

export class Profile extends Component {
  

    constructor(props) {
      super(props);

      this.state = {
          // Get value from localStorage or use default
          isLoggedIn: localStorage.getItem('jwt') || 0,
          userID: localStorage.getItem('id') || 0,
          endDate: localStorage.getItem('enddate') || 0,
          profile: [],
          profile_avatar: [],
          profile_age: [],
          subscription: [],
          subscription_startdate: [],
          subscription_enddate:[],
          isLoaded: false
      }

      // Listen to storage event
      window.addEventListener('storage', (e) => this.storageChanged(e));

      // Bind this to storageChanged()
      this.storageChanged = this.storageChanged.bind(this);
    }



    storageChanged(e) {
        if(e.key === 'isLoggedIn') {
            this.setState({isLoggedIn: e.newValue})
        }
    }
    

    async componentDidMount () {
      const [firstResponse] = await Promise.all([
      axios.get(WP_SERVER_URL + `/wp-json/wp/v2/users/${this.state.userID}?context=edit`, local_user),
    ]);

      const thirdResponse = await axios.get(WP_SERVER_URL + `/wp-json/pmpro/v1/get_membership_level_for_user?user_id=${this.state.userID}`, config);
      this.setState({
          profile: firstResponse.data,
          profile_avatar: firstResponse.data.user_avatar,
          profile_age: firstResponse.data.age,
          subscription: thirdResponse.data,
          subscription_startdate: thirdResponse.data.startdate,
          subscription_enddate: thirdResponse.data.enddate,
          isLoaded: true
      });
      console.log(thirdResponse)
    }

    render() {
      const {profile, profile_avatar, endDate, profile_age, subscription, subscription_startdate, subscription_enddate, isLoggedIn, isLoaded} = this.state;
      console.log(this.state);
      var profile_age_str = profile_age.toString().replace(/-/g, "");
      var pastDate = profile_age_str.slice(0, 4);
      var currentDate_str = dateFormat(new Date());
      var currentDate = currentDate_str.slice(0, 4);
      var age = Math.floor( ( currentDate - pastDate ) );
      let avatarUrl = WP_SERVER_URL + '/wp-content/uploads/2022/10/avatar.png';
      Moment.globalLocale = 'bg';
      // console.log(pastDate)
      // console.log(currentDate)
      // console.log(d)
      return (
        // const age = Math.floor( ( currentDate - pastDate ) * 0.0001 );
              <div className="content-app">
                <section id="portfolio-details" className="portfolio-details">
                  <div className="container aos-init aos-animate" data-aos="fade-up">
                    <div className="section-header-left text-center mb-5">
                      <h2>Здравей, {profile.name}</h2>
                    </div>
                    <div className="row gy-4">
                      <div className="col-lg-1"></div>
                      <div className="col-lg-3">
                        <div className="avatar-item-profile">
                          <img src={avatarUrl} alt="" className="avatar-img-profile" />
                          {/* <img src={profile_avatar.guid} className="avatar-img-profile" alt="" /> */}
                          <div>{profile.name}</div>
                          <div><i className="bi bi-geo-alt"> </i>{profile.location}</div>
                            <div className="profile-meta d-inline-flex">
                              <div className="profile-meta-single"><img src="assets/img/height.png" alt="" /><span style={{paddingLeft: 5 + 'px'}}>{profile.height}</span> см.</div>
                              <div className="profile-meta-single"><img src="assets/img/weight.png" alt="" /><span style={{paddingLeft: 5 + 'px'}}>{profile.weight}</span> кг.</div>
                              <div className="profile-meta-single"><img src="assets/img/age.png" alt="" /><span style={{paddingLeft: 5 + 'px'}}>{age}</span> г.</div>
                            </div>
                            <div className="logout"><button className="btn logout-btn w-75 mt-5" onClick={populateStorage}>Излез</button></div>
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                      <div className="col-lg-6 profile-act-sh">
                        <div className="text-center pt-3"><h3>Информация за вашия абонамент</h3>{"\n"}</div>
                        <div className="text-center pt-3"><h4>Регистрация във FlexyFit: <FakeSuspense delay={5000} ><Moment format="DD/MM/YYYY">{profile.registered_date}</Moment></FakeSuspense></h4></div>
                        <div className="text-center"><h4>Вашия абонамент изтича: <FakeSuspense delay={5000} ><Moment format="DD/MM/YYYY">{endDate}</Moment></FakeSuspense></h4></div>
                        {/*<Moment unix format="DD/MM/YYYY" add={{ days: 30 }}>{subscription.startdate}</Moment>
                         <div className="col-lg-6 d-inline-flex profile-act-sh">
                          <div className="portfolio-info">
                            <h3>Време в тренировка днес</h3>
                              <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="10" style={{ "--value": 100 }}>
                                <div className="circle-text">минути</div>
                              </div>
                          </div>
                          <div className="portfolio-info">
                            <h3>Общо време в тренировка</h3>
                              <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="10" style={{ "--value": 50 }}>
                                <div className="circle-text">Часа</div>
                              </div>
                          </div>
                          <div className="portfolio-info">
                            <h3>Последователни дни</h3>
                              <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="1" style={{ "--value": 20 }}>
                                <div className="circle-text">Дни</div>
                              </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )
    }
}

export default withParams(Profile);