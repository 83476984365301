import { useEffect, useState} from 'react';
import axios from 'axios';
import { WP_SERVER_URL } from '../utils/config';


const useFetchData = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(WP_SERVER_URL + '/wp-json/wp/v2/categories?acf_format=standard&parent=48&acf_format=standard&per_page=100');
        setData(response);
      } catch (error) {
        console.error(error)
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;