import React, { Component } from 'react'
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from "react-router-dom";
import { WP_SERVER_URL } from '../utils/config';

export class PostsItems extends Component {

   state = {
       imgUrl: '',
       series: '',
       isLoaded: true
   }

   static propTypes = {
       post: PropTypes.object.isRequired
   }

   componentDidMount () {
       const {featured_media} = this.props.post;
       const getImageUrl = axios.get(WP_SERVER_URL + `/wp-json/wp/v2/media/${featured_media}`);
      
       Promise.all([getImageUrl]).then(res => {

           console.log(res);
           this.setState({
               imgUrl: res[0].data.media_details.sizes.full.source_url,
               //series: res.data,
               isLoaded: true

           });
       });
    }
 
    render() {
        const {fimg_url, video_length, title, child_category, instructor, video_id} = this.props.post;
        const {imgUrl,  isLoaded} = this.state;
        return (

            <div className="col-xl-4 col-md-6" data-aos="zoom-out">
                <div className="service-item">
                    <Link to={`/video/${video_id}`}>
                        <div className="video-length">{video_length}</div>
                        <div className="img">
                            <img src={fimg_url} alt={title.rendered} className="img-fluid" />
                        </div>
                        <div className="details position-relative">
                            <div className="icon">
                            <i className="bi bi-play-circle"></i>
                            </div>
                            <h3>{title.rendered}</h3>
                            <p>{child_category}</p>
                            <p>{instructor[0].display_name}</p>
                        </div>
                    </Link>
                </div>
            </div>

        )
    }
}

export default PostsItems;