import React, { Component } from 'react'
import {useParams} from 'react-router-dom';
import axios from 'axios';
import InstructorsSeriesItems from './InstructorsSeriesItems';
import { WP_SERVER_URL } from '../utils/config';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const wptoken = `${process.env.REACT_APP_API_KEY_WP}`;

const config = {
        headers: { 'Authorization': `${wptoken}` }
};

export class InstructorsSeries extends Component {

    state = {
        instructors: [],
        isLoaded: false
    }

    componentDidMount () {
        axios.get(WP_SERVER_URL + `/wp-json/wp/v2/categories?instructor=${this.props.id}&per_page=100&acf_format=standard`, config)
        .then(res => this.setState({
            instructors: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err))
    }

    render() {
        const {instructors, isLoaded} = this.state;
        console.log(this.state);
        return (
            <section id="portfolio-details" className="portfolio-details">
                <div className="carousel-item active">
                    <div className="container">
                        <div className="section-header-left" data-aos="fade-up">
                            <h2>Серии в тази категория</h2>
                        </div>
                    </div>
                    <div className="row justify-content-left gy-6">
                        {instructors.map(instructor =>
                            <InstructorsSeriesItems key={instructor.id} instructor={instructor} />
                        )}
                    </div>
                </div>
            </section>
        )
    }
}

export default InstructorsSeries