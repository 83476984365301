import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import axios from 'axios';
import { SERVER_URL } from '../utils/config';

export class SingleSeriesItems extends Component {

    static propTypes = {
        singleserie: PropTypes.object.isRequired,
    }

    state = {
        last_watched: []
    }

    componentDidMount () {
        const userId = localStorage.getItem("id");
        
        axios.post(SERVER_URL + '/api/video/GetUserWatchedVideos',
        {userId: (userId)},
        )
        .then(res => this.setState({
            last_watched: res.data.watchedVideos,
            isLoaded: true
        }))
        .catch(err => console.log(err))
    }

    render() {
        const {fimg_url, video_length, title, order, video_id, isLoaded} = this.props.singleserie;
        const {last_watched} = this.state;
        const result = last_watched.filter(i => i.videoId == video_id);
        function last_watched_func() {
            for (const {
                watchingDate: n,
            } of result) {
                return(`последно гледане: ${n.slice(0, 10)}`);
            }
        }
        // console.log(last_watched_func());
        const watched = last_watched_func();
        return (

            <div className="col-xl-4 col-md-6 single-series-mb" data-aos="zoom-out">
                <div className="service-item">
                    <Link to={`/video/${video_id}`}>
                        <div className="video-length">{video_length}</div>
                        <div className="img">
                            <img src={fimg_url} alt={title.rendered} className="img-fluid" />
                        </div>
                        <div className="details position-relative">
                            <div className="icon">
                            <i className="bi bi-play-circle"></i>
                            </div>
                            <h3>{order}. {title.rendered}</h3>
                            <span>{watched}</span>
                        </div>
                    </Link>
                </div>
            </div>
            
        )
    }
}
export default SingleSeriesItems