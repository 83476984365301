import React, { Component } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import SearchResultsVideosItems from './SearchResultsVideosItems';
import FakeSuspense from './FakeSuspense'
import IsotopeReact from '../pages/IsotopeFiltersVideos'
import { WP_SERVER_URL } from '../utils/config';

export class SearchResultsVideos extends Component {

    constructor() {
        super();
        this.state = {
          searchKeyword: null,
          payloadBox: null,
          searchvideos: []
        };
        
        this.publish = this.publish.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
      
    handleChange({ target }) {
    this.setState({
        [target.name]: target.value
    });
    }

    publish() {
    this.props.history.push({
        pathname: '/events',
        state: { events_data: this.state.searchKeyword }
        })
    console.log( this.state.searchKeyword, this.state.payloadBox );
    }

    componentDidMount () {
        axios.get(WP_SERVER_URL + `/wp-json/relevanssi/v1/search?keyword=${this.props.id}&acf_format=standard&per_page=20`)
        .then(res => this.setState({
            searchvideos: res.data,
            instructor: res.data[0].instructor,
            isLoaded: true
        }))
        .catch(err => console.log(err))
    }

    render() {
        const {searchvideos, isLoaded} = this.state;
        console.log(this.state);
        return (
            <section id="portfolio-details" className="portfolio-details">
                <div className="container" data-aos="fade-up">
                    <div className="row gy-4">  
                        <div className="col-lg-1 text-center mt-3">
                            <button className="btn filter-main-btn w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            <i className="bi bi-filter" style={{fontSize: 1.7 + 'em'}}></i>
                            </button>
                        </div>
                        <div className="col-lg-11 training-search">
                            <div className="input-group">
                                <input type="text" name="searchKeyword" value={ this.state.searchKeyword || "" } onChange={ this.handleChange } className="form-control rounded" placeholder="Търси" aria-label="Търси" aria-describedby="search-addon" />
                                <Link to={`/searchresults/series/${this.state.searchKeyword}`}>
                                <button type="button" className="btn search-btn" onClick={() => setTimeout(() => window.location.reload(), 1000)}>Серии</button>
                                </Link>
                                <Link to={`/searchresults/videos/${this.state.searchKeyword}`}>
                                <button type="button" className="btn search-btn" onClick={() => setTimeout(() => window.location.reload(), 1000)}>Класове</button>
                                </Link>
                                <Link to={`/searchresults/instructors/${this.state.searchKeyword}`}>
                                <button type="button" className="btn search-btn" onClick={() => setTimeout(() => window.location.reload(), 1000)}>Инструктор</button>
                                </Link>
                            </div>
                        </div>
                        <div className="collapse" id="collapseExample">
                            <div className="filters">
                                <div className="ui-group">
                                    <button type="button" onClick={(e) => {e.preventDefault(); window.location.href='/series/48';}} className="btn search-btn">Йога</button>
                                    <button type="button" onClick={(e) => {e.preventDefault(); window.location.href='/series/52';}} className="btn search-btn">Мобилност Пилатес</button>
                                    <button type="button" onClick={(e) => {e.preventDefault(); window.location.href='/series/54';}} className="btn search-btn">Медитации</button>
                                    <button type="button" onClick={(e) => {e.preventDefault(); window.location.href='/series/56';}} className="btn search-btn">Фитнес</button>
                                    <button type="button" onClick={(e) => {e.preventDefault(); window.location.href='/series/58';}} className="btn search-btn">Флекси Мами</button>
                                    <button type="button" onClick={(e) => {e.preventDefault(); window.location.href='/series/193';}} className="btn search-btn">Dance Flow</button>
                                </div>
                                <div className="ui-group">
                                    <FakeSuspense delay={3000} >
                                        <IsotopeReact />
                                    </FakeSuspense>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item active">
                    <div className="container">
                        <div className="section-header-left">
                        <FakeSuspense delay={2000} >
                            {searchvideos < 1 ?
                            <h2>Няма резултати за ключова дума {this.props.id}</h2>
                            :
                            <h2>Резултати за ключова дума {this.props.id}</h2>
                            }
                        </FakeSuspense>
                        </div>
                        <div className="filter-container">
                            <div className="row justify-content-left gy-6">
                                {searchvideos.map(searchvideo =>
                                    <SearchResultsVideosItems key={searchvideo.id}  searchvideo={searchvideo} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default SearchResultsVideos