import React, { Component } from 'react'
import axios from 'axios';
import InstructorsItems from './InstructorsItems';
import { Link } from "react-router-dom";
import { WP_SERVER_URL } from '../utils/config';

const wptoken = `${process.env.REACT_APP_API_KEY_WP}`;

const config = {
        headers: { 'Authorization': `${wptoken}` }
};

export class Instructors extends Component {

    state = {
        instructors: [],
        isLoaded: false
    }

    componentDidMount () {
        axios.get(WP_SERVER_URL + '/wp-json/wp/v2/users?roles=instructors&per_page=50', config)
        .then(res => this.setState({
            instructors: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err))
    }

    render() {
        const {instructors, isLoaded} = this.state;
        console.log(this.state);
        return (
            <section id="team" className="team">
                <div className="container">
                    <div className="section-header">  
                        <div className="section-header-left">
                            <h2>Инструктори</h2>
                        </div>
                    </div>
                    <div className="row gy-5">
                        {instructors.map(instructor =>
                            <div key={instructor.id} className="col-xl-2 col-md-6 d-flex">
                                <InstructorsItems instructor={instructor} />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        )
    }
}

export default Instructors