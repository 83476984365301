import React from 'react';
import Categories from '../components/Categories';
import MostWatched from '../components/MostWatched';
import Instructors from '../components/InstructorsHome';

function Home() {
    return (
        <div className="content-app">
            <Categories />
            <MostWatched />
            <Instructors />
        </div>
    );
}

export default Home;