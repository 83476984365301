import React from 'react';
import Posts from '../components/Posts';

function Contact() {
    return (
        <div className="content-app">
            <Posts />
        </div>
    );
}

export default Contact;