import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from '../Navbar';
import Footer from '../components/Footer';
import CheckMembership from '../components/CheckMembership';


function Layout({children, ...props}) {

    let displayName = props.isLoggedIn ? props.username : 'guest' 
    console.log(props.isLoggedIn)
    let userID = localStorage.getItem('id') || ''
    console.log(userID)

    return (
        <>
            <Navbar />
            {/* <p>Welcome {displayName}!ID:{userID}</p> */}
            <CheckMembership />
            {children}
            <Outlet />
            <Footer />
        </>
    )


}

// export default Header

// const Layout = ({children, ...props}) => {

//     let displayName = props.isLoggedIn ? props.username : 'guest123' 
//     console.log(props.isLoggedIn)

//     return (
//         <>
//             <Navbar />
//             <p>Welcome {displayName}!</p>
//             {children}
//             <Outlet />
//             <Footer />
//         </>
//     );
// };

export default Layout;