import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export class SearchResultsSeriesItems extends Component {

  static propTypes = {
      searchserie: PropTypes.object.isRequired
  }

  render() {
    const {acf, id, slug, name, instructor, series_physical_difficulty, series_technical_difficulty, isLoaded} = this.props.searchserie;
    console.log(this.state);
    return (
      <div className={`filter-item instructor-${instructor[0].id} series_physical_difficulty-${series_physical_difficulty} series_technical_difficulty-${series_technical_difficulty} col-lg-3 col-sm-6 featured-services item ${ slug }`}>
        <Link to={`/singleseries/${id}`}>
          <div className="portfolio-wrapper service-item">
            <div className="img">
              {/* <div class="icon"><i class="bi bi-activity icon"></i></div> */}
              <img src={acf.image} alt="" className="img-fluid img series-img" />
              <div className="series-desc">
                  <div className="series-name">{name}</div>
                  <div className="series-trainer">{instructor[0].display_name}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }
}
export default SearchResultsSeriesItems