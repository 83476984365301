import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export class InstructorsSeriesItems extends Component {

    static propTypes = {
        instructor: PropTypes.object.isRequired
    }

    render() {
        const {acf, name, id, instructor, isLoaded} = this.props.instructor;
        console.log(this.state);
        return (
            <div className={`col-lg-3 col-sm-6 featured-services`}>
            <Link to={`/singleseries/${id}`}>
            <div className="portfolio-wrapper service-item">
              <div className="img">
                {/* <div class="icon"><i class="bi bi-activity icon"></i></div> */}
              <img src={acf.image} alt="" className="img-fluid img series-img" />
                <div className="series-desc">
                    <div className="series-name">{name}</div>
                    <div className="series-trainer">{instructor[0].display_name}</div>
                </div>
              </div>
              {/* <span dangerouslySetInnerHTML={{ __html: description }} /> */}
            </div>
            </Link>
        </div>
        )
    }
}
export default InstructorsSeriesItems