import React, { useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import styled from "styled-components";
import IsoTopeGrid from "react-isotope";
import useFetchData from './IsotopeFiltersTestItems'
import SeriesItems from './SeriesItems';


const filtersDefault = [
    { label: "all", isChecked: true },
    { label: "test", isChecked: false },
    { label: "test1", isChecked: false },
    { label: "chart", isChecked: false },
    { label: "tile", isChecked: false }
  ];

  const Filter = styled("button")`
  background-color: transparent;
  border: none;
  outline: none;

  > input {
    width: 0;
    height: 0;

    &:checked {
      & + label {
        color: blue;
        border-color: blue;
      }
    }
  }

  > label {
    padding: 5px;
    border-bottom: 2px solid transparent;

    &:hover {
      color: blue;
      cursor: pointer;
    }
  }
`;


const MyComponent = () => {
  const {
    data,
    loading,
  } = useFetchData();

  const [filters, updateFilters] = useState(filtersDefault);
  let params = useParams();
  const onFilter = event => {
    const {
      target: { value, checked }
    } = event;

    updateFilters(state =>
      state.map(f => {
        if (f.label === value) {
          return {
            ...f,
            isChecked: checked
          };
        }

        return f;
      })
    );
  };
  const data1 = JSON.stringify(data)
  return (
    <div className="content-app">
    <div>
        {filters.map(f => (
        <Filter key={`${f.label}_key`}>
            <input
            id={f.label}
            type="checkbox"
            value={f.label}
            onChange={onFilter}
            checked={f.isChecked}
            />
            <label htmlFor={f.label}>{f.label}</label>
        </Filter>
        ))}
    </div>
    <div>
    {loading && <div>Loading</div>}
    {!loading && (
      <div>
        <h2>Doing stuff with data</h2>
        <IsoTopeGrid
          gridLayout={data}
          noOfCols={3}
          unitWidth={1000}
          unitHeight={1000}
          filters={filters}
        >    
        {data.map(serie => (
            <div key={serie.id} serie={serie} className={`card instructor-14`} >
              {serie.id}
              </div>
        ))}
        </IsoTopeGrid>
      </div>
    )}
    </div>
    </div>
  )
}

export default MyComponent;