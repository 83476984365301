import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export class FavoritesItems extends Component {

    static propTypes = {
        favoriteslists: PropTypes.object.isRequired
    }

    render() {
        const {fimg_url, video_length, title, child_category, instructor, video_id, isLoaded} = this.props.favoriteslists;
        return (
            <div className="col-xl-4 col-md-6" data-aos="zoom-out">
                <div className="service-item">
                    <Link to={`/video/${video_id}`}>
                        <div className="video-length">{video_length}</div>
                        <div className="img">
                            <img src={fimg_url} alt={title.rendered} className="img-fluid" />
                        </div>
                        <div className="details position-relative">
                            <div className="icon">
                            <i className="bi bi-play-circle"></i>
                            </div>
                            <h3>{title.rendered}</h3>
                            <p>{child_category}</p>
                            <p>{instructor[0].display_name}</p>
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}
export default FavoritesItems