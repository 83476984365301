import React, { useState, useLayoutEffect  } from 'react';
import {useParams} from 'react-router-dom';
// import InstructorsFilters from '../components/InstructorsFilters';
import Isotope from 'isotope-layout';
// import Filterizr from 'filterizr'


const IsotopeReact = () => {
    const params = useParams()
    console.log(params)

    const [id] = useState(false);
    console.log(id)
    
    const [isLoaded, setLoaded] = useState(false); // Loading state
    const [series, setSeries] = useState({ data: {}, loading: false });

    
    const isotope = React.useRef()
    
    const [filterKey, setFilterKey] = React.useState('*')
  
    
    React.useEffect(() => {
      isotope.current = new Isotope('.filter-container', {
        itemSelector: '.filter-item',
        layoutMode: 'fitRows',
      })
     
      return () => isotope.current.destroy()
    }, [])
  
 
    React.useEffect(() => {
      filterKey === '*'
        ? isotope.current.arrange({filter: `*`})
        : isotope.current.arrange({filter: `.${filterKey}`})
    }, [filterKey])
  
    const handleFilterKeyChange = key => () => setFilterKey(key)

    // const options = { /* check next step for available options */ };  
    // const filterizr = new Filterizr('.filter-container', options);
    return (
        <> 
          <div className="filters">
            <div id="physical_difficulty" className="ui-group">
              <h4 className="filter-name">Ниво на трудност</h4>
              <div className="button-group" data-filter-group="physical_difficulty">
                <button type="button" data-filter="" onClick={handleFilterKeyChange('*')} className="btn filter-btn is-checked">Покажи всички</button>
                <button type="button" data-filter=".physical_difficulty-Слабо" onClick={handleFilterKeyChange('physical_difficulty-Слабо')} className="btn filter-btn">Слабо</button>
                <button type="button" data-filter=".physical_difficulty-Умерено" onClick={handleFilterKeyChange('physical_difficulty-Умерено')} className="btn filter-btn">Умерено</button>
                <button type="button" data-filter=".physical_difficulty-Средно" onClick={handleFilterKeyChange('physical_difficulty-Средно')} className="btn filter-btn">Средно</button>
                <button type="button" data-filter=".physical_difficulty-Високо" onClick={handleFilterKeyChange('physical_difficulty-Високо')} className="btn filter-btn">Високо</button>
                <button type="button" data-filter=".physical_difficulty-Интензивно" onClick={handleFilterKeyChange('physical_difficulty-Интензивно')} className="btn filter-btn">Интензивно</button>
              </div>
            </div>
            <div id="technical_difficulty" className="ui-group">
              <h4 className="filter-name">Ниво на интензитет</h4>
              <div className="button-group" data-filter-group="technical_difficulty">
                <button type="button" data-filter="" onClick={handleFilterKeyChange('*')} className="btn filter-btn is-checked">Покажи всички</button>
                <button type="button" data-filter=".technical_difficulty-Слабо" onClick={handleFilterKeyChange('technical_difficulty-Слабо')} className="btn filter-btn">Слабо</button>
                <button type="button" data-filter=".technical_difficulty-Умерено" onClick={handleFilterKeyChange('technical_difficulty-Умерено')} className="btn filter-btn">Умерено</button>
                <button type="button" data-filter=".technical_difficulty-Средно" onClick={handleFilterKeyChange('technical_difficulty-Средно')} className="btn filter-btn">Средно</button>
                <button type="button" data-filter=".technical_difficulty-Високо" onClick={handleFilterKeyChange('technical_difficulty-Високо')} className="btn filter-btn">Високо</button>
                <button type="button" data-filter=".technical_difficulty-Интензивно" onClick={handleFilterKeyChange('technical_difficulty-Интензивно')} className="btn filter-btn">Интензивно</button>
              </div>
            </div>
            <div className="ui-group">
              <h4 className="filter-name">Инструктор</h4>
              <div className="button-group" data-filter-group="instructor">
                <button type="button" data-filter="" onClick={handleFilterKeyChange('*')} className="btn filter-btn is-checked">Покажи всички</button>
                <button type="button" data-filter=".instructor-12" onClick={handleFilterKeyChange('instructor-12')} className="btn filter-btn">Алекс Маслева</button>
                <button type="button" data-filter=".instructor-41" onClick={handleFilterKeyChange('instructor-41')} className="btn filter-btn">Дея Иванова</button>
                <button type="button" data-filter=".instructor-40" onClick={handleFilterKeyChange('instructor-40')} className="btn filter-btn">Диляна Котева</button>
                <button type="button" data-filter=".instructor-11" onClick={handleFilterKeyChange('instructor-11')} className="btn filter-btn">Димитър Стефанов</button>
                <button type="button" data-filter=".instructor-13" onClick={handleFilterKeyChange('instructor-13')} className="btn filter-btn">Екатерина Кьосева</button>
                <button type="button" data-filter=".instructor-49" onClick={handleFilterKeyChange('instructor-49')} className="btn filter-btn">Елизабет Якимова</button>
                <button type="button" data-filter=".instructor-43" onClick={handleFilterKeyChange('instructor-43')} className="btn filter-btn">Евелин Евлогиева</button>
                <button type="button" data-filter=".instructor-42" onClick={handleFilterKeyChange('instructor-42')} className="btn filter-btn">Габи Димитрова</button>
                <button type="button" data-filter=".instructor-39" onClick={handleFilterKeyChange('instructor-39')} className="btn filter-btn">Георги Ранчев</button>
                <button type="button" data-filter=".instructor-14" onClick={handleFilterKeyChange('instructor-14')} className="btn filter-btn">Гери Йошева</button>
                <button type="button" data-filter=".instructor-44" onClick={handleFilterKeyChange('instructor-44')} className="btn filter-btn">Ивaйло Бешев</button>
                <button type="button" data-filter=".instructor-45" onClick={handleFilterKeyChange('instructor-45')} className="btn filter-btn">Коко Андонов</button>
                <button type="button" data-filter=".instructor-46" onClick={handleFilterKeyChange('instructor-46')} className="btn filter-btn">Михаил Мишев</button>
                <button type="button" data-filter=".instructor-47" onClick={handleFilterKeyChange('instructor-47')} className="btn filter-btn">Надя Спасова</button>
                <button type="button" data-filter=".instructor-9" onClick={handleFilterKeyChange('instructor-9')} className="btn filter-btn">Райя Куцарова</button>
                <button type="button" data-filter=".instructor-17" onClick={handleFilterKeyChange('instructor-17')} className="btn filter-btn">Сандра Занева</button>
                <button type="button" data-filter=".instructor-48" onClick={handleFilterKeyChange('instructor-48')} className="btn filter-btn">Цвети Гигова</button>
                <button type="button" data-filter=".instructor-10" onClick={handleFilterKeyChange('instructor-10')} className="btn filter-btn">Вели Младенова</button>
                <button type="button" data-filter=".instructor-15" onClick={handleFilterKeyChange('instructor-15')} className="btn filter-btn">Весела Ламбер</button>
              </div>
            </div>
            {/* <InstructorsFilters /> */}
          </div>
           
        </>
    )
   
}
  
//   ReactDOM.render(<IsotopeReact />, document.getElementById('isotope'))
  export default IsotopeReact