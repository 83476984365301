import React, { Component } from 'react'
import axios from 'axios';
import FavoritesItems from './FavoritesItems';
import { SERVER_URL } from '../utils/config';
import { WP_SERVER_URL } from '../utils/config';

export class Favorites extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            favoriteslist: [],
            userID: localStorage.getItem('id') || 0,
            isLoaded: true
        }
    }  

    async componentDidMount () {
        axios
        .post(SERVER_URL+ '/api/favorites/GetUserFvorites', 
        {userId: (this.state.userID)}
        )
        .then((response) => {
            const id_string = JSON.stringify(response.data.favorites).replace(/[\[\]"]+/g,'')
            console.log(response.data.favorites)
            if (response.data.favorites.length > 0) {
            axios
                .get(WP_SERVER_URL + `/wp-json/wp/v2/posts?include=${id_string}`)
                .then(res => this.setState({
                    favoriteslist: res.data,
                    isLoaded: true
            }))
            } else {
                this.setState({
                    isLoaded: false
                })
            }
        })
    }

   render() {
    const {favoriteslist, isLoaded} = this.state;
    console.log(favoriteslist)
    // const result = Array.isArray(favoriteslist) ? favoriteslist.join(',') : Object.values(favoriteslist);
       return (
           <div className="content-app">
                <div id="services" className="services mt-5 pb-5">
                    <div className="container">
                        <div className="section-header-left">
                            {isLoaded ?
                                <h2>Любими класове</h2>
                                :
                                <h2>В момента нямате любими класове</h2>
                            }
                        </div>
                        <div className="row gy-5"> 
                            {favoriteslist.map(favoriteslists =>
                                <FavoritesItems key={favoriteslists.id}  favoriteslists={favoriteslists} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
       )
   }
}
export default Favorites