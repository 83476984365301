import { React } from "react";
import { Link } from "react-router-dom";
import { WP_SERVER_URL } from "./utils/config";

function populateStorage() {
    localStorage.clear()
    window.location.href = "/login";
}

function Navbar() {
    let avatarUrl = WP_SERVER_URL + '/wp-content/uploads/2022/10/avatar.png';
    return (
        <header id="header" className="header fixed-top sticked" data-scrollto-offset="0">
            <div className="container-fluid d-flex align-items-center justify-content-between">
                <a href="/" className="logo d-flex align-items-center scrollto me-auto me-lg-0">
                    <img src="assets/img/logoflexy.png" alt=""/>
                    <img src="assets/img/logo.png" alt=""/>
                </a>
                <nav id="navbar" className="navbar">
                    <ul>
                        <li><Link to="/">Начало</Link></li>
                        <li><a className="nav-link scrollto" href="/favorites">Любими</a></li>
                        <li><a className="nav-link scrollto" href="/newest">Най-нови</a></li>
                        <li><Link to="/instructors">Инструктори</Link></li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle d-none"></i>
                </nav>
                <div className="avatar-item">
                    <Link to="/profile/"><img src={avatarUrl} alt="" className="avatar-img" /></Link>
                </div>
            </div>
        </header>
    )
}

export default Navbar;