import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export class SeriesItems extends Component {

    static propTypes = {
        serie: PropTypes.object.isRequired
    }

    render() {
        const {acf, count, totalclasstime, name, instructor, id, series_physical_difficulty, series_technical_difficulty, isLoaded} = this.props.serie;
        console.log(this.state);
        return (
        <div className={`filter-item instructor-${instructor[0].id} series_physical_difficulty-${series_physical_difficulty} series_technical_difficulty-${series_technical_difficulty} col-lg-3 col-sm-6 featured-services`}>
            <Link to={`/singleseries/${id}`}>
            <div className="portfolio-wrapper service-item">
              {/* <div className="class-count">{count}</div> */}
              <div className="img">

                <img src={acf.image} alt="" className="img-fluid img series-img" />
                <div className="series-desc">
                    <div className="series-name">{name}</div>
                    <div className="series-trainer">{instructor[0].display_name}</div>
                    <div className="series-count-time">Брой класове {count}</div>
                    <div className="series-count-time">Обща продължителност {totalclasstime}</div>
                </div>
              </div>
              {/* <span dangerouslySetInnerHTML={{ __html: description }} /> */}
            </div>
            </Link>
        </div>
        
            
        )
    }
}
export default SeriesItems