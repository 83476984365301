import React, { Component } from 'react'
import {useParams} from 'react-router-dom';
import axios from 'axios';
import VideoPlayer from 'react-video-js-player';
import 'video.js/dist/video-js.css';
import FakeSuspense from '../components/FakeSuspense'
import VideoDetails from '../components/VideoDetails';
import VimeoQuality from '../components/VimeoQuality';
import WatchTimePost from '../components/WatchTimePost';
import CheckMembership from '../components/CheckMembership';
import { SERVER_URL, WP_SERVER_URL } from '../utils/config';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function delete_user() {
    if ( this.state.userID === 23 ) {
        localStorage.clear()
      }
}

setTimeout(function(){
    document.getElementById('pleasewaitScreen');
}, 60000);


const vimeo_config = {
    headers: { 'Authorization': 'Bearer 5624d7833fa48edd99116442d0dd1008' }
};

const wptoken = `${process.env.REACT_APP_API_KEY_WP}`;

const config = {
        headers: { 'Authorization': `${wptoken}` }
};

function dateFormat(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    return `${year}/${month}/${day}`;
  }
  

export class Video extends Component {
  
    onPlayerReady(player){
        console.log("Player is ready: ", player);
        this.player = player;
    }
  
    onVideoPlay(duration){
        console.log("Video played at: ", duration);
    }
  
    onVideoPause(duration){
        console.log("Video paused at: ", duration);
    }
  
    onVideoTimeUpdate(duration){
        console.log("Time updated: ", duration);
    }
  
    onVideoSeeking(duration){
        console.log("Video seeking: ", duration);
    }
  
    onVideoSeeked(from, to){
        console.log(`Video seeked from ${from} to ${to}`);
    }
  
    onVideoEnd(){
        console.log("Video ended");
    }

    constructor(props) {
        super(props);
        this.state = {
            showIframe: false,
            videos: [],
            test: [],
            video_details: [],
            testtest: [],
            userID: localStorage.getItem('id') || 0,
            userRole: localStorage.getItem('role') || 0
            // watchtime_access: true
        };
    }

    

    async componentDidMount () {
        const [firstResponse, secondResponse] = await Promise.all([
        axios.get(`https://api.vimeo.com/me/videos/${this.props.params.id}`, vimeo_config),

        axios.get(WP_SERVER_URL + `/wp-json/wp/v2/posts?video_id=${this.props.params.id}`, config)
    ]);
        const user_id = this.state.userID;
        const currentDate = dateFormat(new Date());
        const thirdResponse = await axios.post(SERVER_URL + '/api/video/GetUserVideoData',
        {userId: (user_id), date: (currentDate)} //{userId: (user_id), date: (currentDate)}
      );
    //   console.log(JSON.stringify(thirdResponse, null, 4))
    //   console.log(this.state.userRole)
        // console.log(currentDate)
        this.setState({
            watchtime_access: thirdResponse.data.hasAccess,
            video_details: secondResponse.data[0],
            video_categories: JSON.stringify(secondResponse.data[0].categories).replace(/]|[[]/g, ''),
            videos: firstResponse.data,
			test: firstResponse.data.files,
            showIframe: true,
            isLoaded: true
        });
    }

    render() {


        
        this.state.test.sort((a,b)=> (a.rendition > b.rendition ? 1 : -1))
        const testtest = this.state.test[0];
        const { showIframe } = this.state;
        const {test, video_details, video_categories, watchtime_access, userRole, isLoaded} = this.state;
        // const currentDate = dateFormat(new Date());
        // console.log(currentDate)

        return (
            <section onContextMenu={(e)=> e.preventDefault()} id="content-app" className="content-app">
                <div className="container">
                    
                    {test.map(test1 =>
                        <VimeoQuality key={test1.rendition}  test1={test1} />
                    )}

                    <div  className="video-wrapper" data-setup='{"fluid": true}'>
                    {watchtime_access ?
                        <VideoPlayer
                            controls={true}
                            src={testtest.link}
                            poster={video_details.fimg_url}
                            height="898"
                            onReady={this.onPlayerReady.bind(this)}
                            onPlay={this.onVideoPlay.bind(this)}
                            onPause={this.onVideoPause.bind(this)}
                            onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                            onSeeking={this.onVideoSeeking.bind(this)}
                            onSeeked={this.onVideoSeeked.bind(this)}
                            onEnd={this.onVideoEnd.bind(this)}
                            // className="video-wrapper"
                        />
                        :
                        <FakeSuspense delay={3000} >
                            <div id="pleasewaitScreen">
                                <div className="nomembership-text">
                                    Достигнали сте дневното ограничението за гледане на видео тренировки<br /><br />
                                    С цел предпазване на съдържанието на платформата, всеки абонат на FlexyFit има право на неограничено потребление в рамките на 150 минути на ден<br /> 
                                    (съгласно Общите условия за ползване на платформата). <br /><br />
                                    В случай на грешка, моля пишете ни на info@flexyfit.bg
                                </div>
                                <img src={video_details.fimg_url} alt="" className="img-fluid img" />
                            </div>
                        </FakeSuspense>
                    }
                    </div>
                    <VideoDetails />
                    {userRole === 'instructors' ? 
                        <div></div>
                    :
                        <FakeSuspense delay={5000} >
                            <WatchTimePost video={this.props.params.id} class_id={video_details.id} video_categories={video_categories} />
                        </FakeSuspense>
                    }
                    <CheckMembership />
                </div>
            </section>
        )
    }
}

export default withParams(Video);