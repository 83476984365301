import React, { Component, useState, useEffect } from 'react'
import axios from 'axios';
import CategoriesItems from './CategoriesItems';
import { WP_SERVER_URL } from '../utils/config';

const wptoken = `${process.env.REACT_APP_API_KEY_WP}`;

const config = {
        headers: { 'Authorization': `${wptoken}` }
};


export class Categories extends Component {

   state = {
       categories: [],
       isLoaded: false
   }

    componentDidMount () {
        console.log(WP_SERVER_URL + '/wp-json/wp/v2/categories?acf_format=standard&parent=0')
        axios.get(WP_SERVER_URL + '/wp-json/wp/v2/categories?acf_format=standard&parent=0')
        .then(res => this.setState({
            categories: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err))
   }

    render() {
        const {categories, isLoaded} = this.state;
        console.log(this.state);
        return (
                <section  id="hero" className="hero carousel  carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">
                    <div className="carousel-item active">
                        <div className="container">
                            <div className="section-header-left">
                                <h2>Какво ще тренираш <strong>Днес?</strong></h2>
                            </div>
                            <div className="row justify-content-left gy-6">
                                {categories.map(category =>
                                    <CategoriesItems key={category.id}  category={category} />
                                )}
                            </div>
                        </div>
                    </div>
                </section>
        )
    }
}
export default Categories