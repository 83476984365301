import React, { Component } from 'react'
import {useParams} from 'react-router-dom';
import SearchResultsSeries from '../components/SearchResultsSeries';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export class SearchResultsSeriesMain extends Component {

    render() {
        console.log(this.state);
        return (
            <div className="content-app">
                <SearchResultsSeries
                    id={this.props.params.id}
                />
            </div>
        )
    }
}

export default withParams(SearchResultsSeriesMain);