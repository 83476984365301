import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export class SearchResultsInstructorsItems extends Component {

  static propTypes = {
    searchinstructor: PropTypes.object.isRequired
  }

  render() {
    const {instructor_avatar, id, name, trainer_desc, instructor, vimeo_video_id, isLoaded} = this.props.searchinstructor;
    console.log(this.state);
    return (
      <div className={`col-lg-3 col-sm-6 featured-services`}>
        <Link to={`/instructor/${id}`}>
          <div className="portfolio-wrapper service-item">
            <div className="img">
              {/* <div class="icon"><i class="bi bi-activity icon"></i></div> */}
              <img src={instructor_avatar.guid} alt="" className="img-fluid img series-img" />
              <div className="series-desc">
                  <div className="series-name">{name}</div>
                  <div className="series-trainer">{trainer_desc}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }
}
export default SearchResultsInstructorsItems