import React, { useState, useLayoutEffect  } from 'react';
import {useParams} from 'react-router-dom';
import WatchTime from '../components/WatchTime';
import WatchTimePost from '../components/WatchTimePost';
// import Filterizr from 'filterizr'



const IsotopeReactTest = () => {

    // const options = { /* check next step for available options */ };  
    // const filterizr = new Filterizr('.filter-container', options);
    return (
        <>
            <div className="content-app">

                    <WatchTimePost />
                </div>
            
        </>
    )
   
}
  
//   ReactDOM.render(<IsotopeReact />, document.getElementById('isotope'))
  export default IsotopeReactTest;