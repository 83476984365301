import React, { Component } from 'react'
import {useParams} from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import InstructorsSeries from '../components/InstructorsSeries';
import Instructors from '../components/Instructors';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export class Instructor extends Component {

    state = {
        instructor: [],
        instructor_avatar: [],
        isLoaded: false
    }

    render() {
        const {instructor, instructor_avatar, isLoaded} = this.state;
        console.log(this.state);
        return (
            <div className="content-app">
                <section id="portfolio-details" className="portfolio-details">
                    <div className="container aos-init aos-animate" data-aos="fade-up">

                        <div className="row gy-4">

                        {/* <div className="col-lg-3">
                            <div className="avatar-item-profile">
                            <img src={instructor_avatar.guid} className="avatar-img-profile" alt="" />
                            <div><strong>{instructor.name}</strong></div>
                            </div>
                        </div>

                        <div className="col-lg-1">

                        </div> */}

                        {/* <div className="col-lg-8 profile-act-sh">
                            <div className="trainer-info">
                            <h5>{instructor.description}</h5>
                            <div><Link to="#">Виж още</Link></div>
                            </div>
                        </div> */}
                        {/* <InstructorsSeries /> */}

                        <Instructors />
                        </div>

                    </div>
                </section>
            </div>


        )
    }
}

export default withParams(Instructor);