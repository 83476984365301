import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation  } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Series from "./pages/Series";
import SearchResultsVideos from "./pages/SearchResultsVideos";
import SearchResultsSeries from "./pages/SearchResultsSeries";
import SearchResultsInstructors from "./pages/SearchResultsInstructors";
import SingleSeries from "./pages/SingleSeries";
import IsotopesFiltersTest from "./pages/IsotopesFiltersTest";
import Video from "./pages/Video";
import Instructor from "./pages/Instructor";
import Instructors from "./pages/Instructors";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import Contact from "./pages/Contact";
import Statistics from "./pages/Statistics";
import NoSubscription from "./pages/NoSubscription";
import NoPage from "./pages/NoPage";
import Test from "./pages/Test";
import Favorites from './components/Favorites'
import Newest from './pages/Newest'
import Login from './components/Login'
import ProfilePage from './components/ProfilePage'
// import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
//import ScrollToTop from './components/ScrollToTop';
// Sets the moment instance to use.
// Moment.globalMoment = moment;



function App() {


  return (
    <HttpsRedirect>
      <BrowserRouter>
        <Inner />
      </BrowserRouter>
    </HttpsRedirect>
  );
}

function Inner() {
  const { pathname, hash, key } = useLocation();
  const [username, setUsername] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [serverMessage, setServerMessage] = useState(false)
  //function using pathname hash and key
  const location = useLocation();
  console.log(location.pathname);

  useEffect(() => {
    window.scroll(0,0);

    
    console.log('Location changed');


  }, [location]);
  
  // console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)
  return(


      <Routes>
      
        <Route path="/" element={<Layout username={username} isLoggedIn={isLoggedIn} serverMessage={serverMessage} />}>
          <Route exact path="/login">
            <Route exact path="/login" element={<Login setUsername={setUsername} setIsLoggedIn={setIsLoggedIn} serverMessage={serverMessage} setServerMessage={setServerMessage} />}/>
          </Route>
          <Route index element={<Home />} />
          <Route path="/series" element={<Series />}>
            <Route path=":id" element={<Series />} />
          </Route>
          <Route>
            <Route path="/searchresults/videos/:id" element={<SearchResultsVideos />} />
            <Route path="/searchresults/series" element={<SearchResultsSeries />} />
            <Route path="/searchresults/series/:id" element={<SearchResultsSeries />} />
            <Route path="/searchresults/instructors" element={<SearchResultsInstructors />} />
            <Route path="/searchresults/instructors/:id" element={<SearchResultsInstructors />} />
            <Route path="/searchresults/*" element={<NoPage />} />
          </Route>
          <Route path="/singleseries" element={<SingleSeries />}>
            <Route path=":id" element={<SingleSeries />} />
          </Route>
          <Route path="/video" element={<Video />}>
            <Route path=":id" element={<Video />} />
          </Route>
          <Route path="/instructor" element={<Instructor />}>
            <Route path=":id" element={<Instructor />} />
          </Route>
          <Route path="instructors" element={<Instructors />} />
          <Route path="/profile" element={<Profile isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} username={username} serverMessage={serverMessage} setServerMessage={setServerMessage}/>}>
            <Route path=":id" element={<Profile />} />
          </Route>
          <Route exact path="/profilepage">
            <Route exact path="/profilepage" element={<ProfilePage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} username={username} serverMessage={serverMessage} setServerMessage={setServerMessage} />}/>
          </Route>
          <Route path="/test" element={<Test />}>
            <Route path=":id" element={<Test />} />
          </Route>
          <Route path="favorites" element={<Favorites />} />
          <Route path="newest" element={<Newest />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="nosubscription" element={<NoSubscription />} />
          <Route path="settings" element={<Settings />} />
          <Route path="contact" element={<Contact />} />
          <Route path="/isotope" element={<IsotopesFiltersTest />}>
            {/* <Route path=":id" element={<IsotopesFiltersTest />} /> */}
          </Route>
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>

  )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App tab="home" />);