import React, { Component } from 'react'
import axios from 'axios';
import MostWatchedItems from './MostWatchedItems';
import { SERVER_URL } from '../utils/config';
import { WP_SERVER_URL } from '../utils/config';

export class MostWatched extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            // Get value from localStorage or use default
            getmostwatched: [],
            viewmostwatched: [],
            userID: localStorage.getItem('id') || 0,
            isLoaded: false
        }
    }  


    async componentDidMount () {
        const [firstResponse] = await Promise.all([
        axios.get(SERVER_URL + '/api/video/GetMostViewedVideos'),
    ]);

        const thirdResponse = await axios.get(WP_SERVER_URL + `/wp-json/wp/v2/posts?include=${firstResponse.data.mostViewedVideos}&orderby=include`);
        this.setState({
            getmostwatched: firstResponse.data,
			viewmostwatched: thirdResponse.data
        });
        console.log(thirdResponse);
    }

   render() {
    const {viewmostwatched, mostViewedVideos, isLoaded} = this.state;
    // const result = Array.isArray(favoriteslist) ? favoriteslist.join(',') : Object.values(favoriteslist);

       return (
           <div className="content-app">
                <div id="services" className="services mt-5 pb-5">
                    <div className="container">
                        <div className="section-header-left">
                            <h2>Най-гледани класове</h2>
                        </div>
                        <div className="row gy-5">
                            {viewmostwatched.slice(0, 6).map(viewmostwatchedvids =>
                                <MostWatchedItems key={viewmostwatchedvids.id}  viewmostwatchedvids={viewmostwatchedvids} />
                            )}
                        </div>
                        {mostViewedVideos}
                    </div>
                </div>
            </div>
       )
   }
}
export default MostWatched