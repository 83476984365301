import React from 'react'

function Footer() {
  return (

    <footer id="footer" className="footer">
        <div className="footer-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-info">
                            <h3>FlexyFit</h3>
                            <p>
                                <strong>Email:</strong> info@flexyfit.bg<br />
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 footer-links">
                        <h4>Полезни връзки</h4>
                        <ul>
                            <li><i className="bi bi-chevron-right"></i> <a href="/">Начало</a></li>
                            <li><i className="bi bi-chevron-right"></i> <a href="/favorites">Любими</a></li>
                            <li><i className="bi bi-chevron-right"></i> <a href="/newest">Най-нови</a></li>
                            <li><i className="bi bi-chevron-right"></i> <a href="/instructors">Инструктори</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-legal text-center">
            <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
                <div className="d-flex flex-column align-items-center align-items-lg-start">
                <div className="copyright">
                    <strong><span>FlexyFit WebApp {process.env.REACT_APP_VERSION}</span></strong>
                </div>
                </div>
                <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
                    <a href="https://www.facebook.com/FlexyFitBG/" className="facebook"><i className="bi bi-facebook"></i></a>
                    <a href="https://www.youtube.com/@flexyfit801/" className="youtube"><i className="bi bi-youtube"></i></a>
                </div>
            </div>
        </div>
    </footer>

    )
}

export default Footer